<template>

  <div id="sidebar2"

       tabindex="-1"
       :class=" seen ? activeOpen : activeClose "

       class="h-screen  menu text-white grid  items-center justify-center nunito static  text-center shadow w-0 z-50 ">


    <SidebarList

        :open="open"
        :activeOpen="activeOpen"
        :activeClose="activeClose"
        :seen="seen"
        :partialsOn="partialsOn"
        :sectionsOn="sectionsOn"
        :pagesOn="pagesOn"
        :animationsOn="animationsOn"
    >
      <template v-slot:partials>
        <slot></slot>
      </template>
      <template v-slot:sections>
        <slot></slot>
      </template>
      <template v-slot:pages>
        <slot></slot>
      </template>
      <template v-slot:animations>
        <slot></slot>
      </template>
    </SidebarList>

  </div>

</template>

<script>


import SidebarList from "@/components/SidebarList"


export default {
  name: "Sidebar",

  components: {SidebarList},

  props: ['seen', 'partialsOn', 'pagesOn', 'sectionsOn','animationsOn','activeOpen','activeClose','open'],




}
</script>

<style scoped>

#sidebar2:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  transition: ease-in-out all 1s;
  /*shadow-2xl*/
}



</style>
