import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import PageTransitionsNr1 from "../components/partials/PageTransitions/PageTransitionsNr1.vue";


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home

  },

  {
    path: '/accordions',
    name: 'Accordions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Accordions" */ '../views/partials/Accordions.vue')
  },


  {
    path: '/alertbanners',
    name: 'AlertBanners',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AlertBanners" */ '../views/partials/AlertBanners.vue')
  },




  {
    path: '/badges',
    name: 'Badges',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Badges" */ '../views/partials/Badges.vue')
  },

  {
    path: '/borders',
    name: 'Borders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Borders" */ '../views/partials/Borders.vue')
  },


  {
    path: '/breadcrumbs',
    name: 'Breadcrumbs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Breadcrumbs" */ '../views/partials/Breadcrumbs.vue')
  },


  {
    path: '/buttons',
    name: 'Buttons',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Buttons" */ '../views/partials/Buttons.vue')
  },


  {
    path: '/calendars',
    name: 'Calendars',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Calendars" */ '../views/partials/Calendars.vue')
  },


  {
    path: '/cards',
    name: 'Cards',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Cards" */ '../views/partials/Cards.vue')
  },


  {
    path: '/checkboxestoggles',
    name: 'CheckboxesToggles',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CheckboxesToggles" */ '../views/partials/CheckboxesToggles.vue')
  },


  {
    path: '/dropdowns',
    name: 'Dropdowns',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Dropdowns" */ '../views/partials/Dropdowns.vue')
  },

  {
    path: '/flyoutmenus',
    name: 'FlyoutMenus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "FlyoutMenus" */ '../views/partials/FlyoutMenus.vue')
  },


  {
    path: '/hamburgermenus',
    name: 'HamburgerMenus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HamburgerMenus" */ '../views/partials/HamburgerMenus.vue')
  },


  {
    path: '/hamburgermenunr1',
    name: 'HamburgerMenuNr1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HamburgerMenuNr1" */ '../components/partials/HamburgerMenus/HamburgerMenuNr1.vue')
  },

  {
    path: '/hamburgermenunr2',
    name: 'HamburgerMenuNr2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HamburgerMenuNr1" */ '../components/partials/HamburgerMenus/HamburgerMenuNr2.vue')
  },

  {
    path: '/hamburgermenunr3',
    name: 'HamburgerMenuNr3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HamburgerMenuNr3" */ '../components/partials/HamburgerMenus/HamburgerMenuNr3.vue')
  },



  {
    path: '/hamburgermenunr4',
    name: 'HamburgerMenuNr4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HamburgerMenuNr4" */ '../components/partials/HamburgerMenus/HamburgerMenuNr4.vue')
  },


  {
    path: '/hamburgermenunr5',
    name: 'HamburgerMenuNr5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HamburgerMenuNr5" */ '../components/partials/HamburgerMenus/HamburgerMenuNr5.vue')
  },



  {
    path: '/hamburgermenunr6',
    name: 'HamburgerMenuNr6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HamburgerMenuNr6" */ '../components/partials/HamburgerMenus/HamburgerMenuNr6.vue')
  },



  {
    path: '/hamburgermenunr7',
    name: 'HamburgerMenuNr7',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "HamburgerMenuNr7" */ '../components/partials/HamburgerMenus/HamburgerMenuNr7.vue')
  },

  {
    path: '/hamburgers',
    name: 'Hamburgers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Hamburgers" */ '../views/partials/Hamburgers.vue')
  },


  {
    path: '/icons',
    name: 'Icons',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Icons" */ '../views/partials/Icons.vue')
  },

  {
    path: '/inputfields',
    name: 'InputFields',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "InputFields" */ '../views/partials/InputFields.vue')
  },

  {
    path: '/loadingspinners',
    name: 'LoadingSpinners',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "LoadingSpinners" */ '../views/partials/LoadingSpinners.vue')
  },


  {
    path: '/menus',
    name: 'Menus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Menus" */ '../views/partials/Menus.vue')
  },


  {
    path: '/menunr1',
    name: 'MenuNr1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "MenuNr1" */ '../components/partials/Menus/MenuNr1.vue')
  },

  {
    path: '/menunr2',
    name: 'MenuNr2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "MenuNr2" */ '../components/partials/Menus/MenuNr2.vue')
  },


  {
    path: '/menunr3',
    name: 'MenuNr3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "MenuNr3" */ '../components/partials/Menus/MenuNr3.vue')
  },
  {
    path: '/menunr4',
    name: 'MenuNr4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "MenuNr4" */ '../components/partials/Menus/MenuNr4.vue')
  },



  {
    path: '/modals',
    name: 'Modals',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Modals" */ '../views/partials/Modals.vue')
  },


  {
    path: '/navigations',
    name: 'Navigations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Navigations" */ '../views/partials/Navigations.vue')
  },



  {
    path: '/paginationnr1',
    name: 'PaginationNr1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PaginationNr1" */ '../components/partials/Paginations/PaginationNr1.vue')
  },



  {
    path: '/notifications',
    name: 'Notifications',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Notifications" */ '../views/partials/Notifications.vue')
  },

  {
    path: '/pagetransitions',
    name: 'PageTransitions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PageTransitions" */ '../views/partials/PageTransitions.vue')
  },

  {
    path: '/pagetransitions1',
    redirect: '/broghue',
    name: 'PageTransitionsNr1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PageTransitionsNr1,


    children: [

      {

        path: '/broghue',
        name: 'Broghue',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Broghue" */ '@/views/partials/PageTransitionsNr1/broghue')
      },

      {
        path: '/mojarico',
        name: 'Mojarico',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Mojarico" */ '@/views/partials/PageTransitionsNr1/mojarico')
      },

      {
        path: '/bakino',
        name: 'Bakino',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Bakino" */ '@/views/partials/PageTransitionsNr1/bakino')
      },

    ]



  },

  {
    path: '/pagetransitions2',
    name: 'PageTransitionsNr2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PageTransitions2" */ '../components/partials/PageTransitions/PageTransitionsNr2')


  },





  {
    path: '/pagetransitions3',
    redirect: '/pagetransitions3/fade',
    name: 'PageTransitionsNr3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PageTransitions3" */ '../components/partials/PageTransitions/PageTransitionsNr3'),


    children: [


      {

        path: '/pagetransitions3/fade',
        name: 'Fade',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Fade" */ '@/views/partials/PageTransitionsNr3/Fade')
      },

      {
        path: '/pagetransitions3/slide',
        name: 'Slide',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Slide" */ '@/views/partials/PageTransitionsNr3/Slide')
      },

      {
        path: '/pagetransitions3/slideup',
        name: 'SlideUp',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SlideUp" */ '@/views/partials/PageTransitionsNr3/SlideUp')
      },


      {

        path: '/pagetransitions3/zoom',
        name: 'Zoom',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Zoom" */ '@/views/partials/PageTransitionsNr3/Zoom')
      },

      {
        path: '/pagetransitions3/flipx',
        name: 'FlipX',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "FlipX" */ '@/views/partials/PageTransitionsNr3/FlipX')
      },

      {
        path: '/pagetransitions3/flipy',
        name: 'FlipY',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "FlipY" */ '@/views/partials/PageTransitionsNr3/FlipY')
      },


    ]


  },





  {
    path: '/pagetransitions4',
    name: 'PageTransitionsNr4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PageTransitions4" */ '../components/partials/PageTransitions/PageTransitionsNr4')


  },



  {
    path: '/paginations',
    name: 'Paginations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Paginations" */ '../views/partials/Paginations.vue')
  },





  {
    path: '/select',
    name: 'Select',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Select" */ '../views/partials/Select.vue')
  },

  {
    path: '/sidebars',
    name: 'Sidebars',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Sidebars" */ '../views/partials/Sidebars.vue')
  },

  {
    path: '/slideovers',
    name: 'SlideOvers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "SlideOvers" */ '../views/partials/SlideOvers.vue')
  },


  {
    path: '/steps',
    name: 'Steps',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Steps" */ '../views/partials/Steps.vue')
  },



  {
    path: '/tabs',
    name: 'Tabs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Tabs" */ '../views/partials/Tabs.vue')
  },

  {
    path: '/timelines',
    name: 'Timelines',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Timelines" */ '../views/partials/Timelines.vue')
  },

  {
    path: '/tooltips',
    name: 'Tooltips',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Tooltips" */ '../views/partials/Tooltips.vue')
  },



  {
    path: '/404pages',
    name: '404Pages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "404Pages" */ '../views/pagesections/404Pages.vue')
  },



  {
    path: '/authentications',
    name: 'Authentications',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Authentications" */ '../views/pagesections/Authentications.vue')
  },





  {
    path: '/blogs',
    name: 'Blogs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Blogs" */ '../views/pagesections/Blogs.vue')
  },





  {
    path: '/contacts',
    name: 'Contacts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contacts" */ '../views/pagesections/Contacts.vue')
  },



  {
    path: '/contents',
    name: 'Contents',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contents" */ '../views/pagesections/Contents.vue')
  },



  {
    path: '/cta',
    name: 'CTA',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CTA" */ '../views/pagesections/CTA.vue')
  },



  {
    path: '/faq',
    name: 'FAQ',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "FAQ" */ '../views/pagesections/FAQ')
  },
  {
    path: '/features',
    name: 'Features',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Features" */ '../views/pagesections/Features.vue')
  },



  {
    path: '/footers',
    name: 'Footers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Footers" */ '../views/pagesections/Footers.vue')
  },


  {
    path: '/forms',
    name: 'Forms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Forms" */ '../views/pagesections/Forms.vue')
  },


  {
    path: '/headers',
    name: 'Headers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Headers" */ '../views/pagesections/Headers.vue')
  },




  {
    path: '/heros',
    name: 'Heros',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Heros" */ '../views/pagesections/Heros.vue')
  },



  {
    path: '/lists',
    name: 'Lists',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Lists" */ '../views/pagesections/Lists.vue')
  },


  {
    path: '/newsletters',
    name: 'Newsletters',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Lists" */ '../views/pagesections/Newsletters.vue')
  },



  {
    path: '/onboarding',
    name: 'Onboarding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Onboarding" */ '../views/pagesections/OnBoarding.vue')
  },



  {
    path: '/pricing',
    name: 'Pricing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Pricing" */ '../views/pagesections/Pricing.vue')
  },




  {
    path: '/sliders',
    name: 'Sliders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Sliders" */ '../views/pagesections/Sliders.vue')
  },




  {
    path: '/stats',
    name: 'Stats',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Stats" */ '../views/pagesections/Stats.vue')
  },



  {
    path: '/team',
    name: 'Team',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Team" */ '../views/pagesections/Team.vue')
  },

  {
    path: '/testimonials',
    name: 'Testimonials',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Testimonials" */ '../views/pagesections/Testimonial.vue')
  },





  {
    path: '/aboutpages',
    name: 'AboutPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AboutPages" */ '../views/pages/AboutPages.vue')
  },




  {
    path: '/animatedpages',
    name: 'AnimatedPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AnimatedPages" */ '../views/pages/AnimatedPages.vue')
  },




  {
    path: '/categorypages',
    name: 'CategoryPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CategoryPages" */ '../views/pages/CategoryPages.vue')
  },




  {
    path: '/checkoutpages',
    name: 'CheckoutPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CheckoutPages" */ '../views/pages/CheckoutPages.vue')
  },


  {
    path: '/landingpages',
    name: 'LandingPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "LandingPages" */ '../views/pages/LandingPages.vue')
  },


  {
    path: '/landingpagenr1',
    name: 'LandingPageNr1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "LandingPageNr1" */ '../components/pages/LandingPages/LandingPageNr1.vue')
  },



  {
    path: '/orderdetailpages',
    name: 'OrderDetailPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "OrderDetailPages" */ '../views/pages/OrderDetailPages.vue')
  },



  {
    path: '/orderhistorypages',
    name: 'OrderHistoryPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "OrderHistoryPages" */ '../views/pages/OrderHistoryPages.vue')
  },



  {
    path: '/pricingpages',
    name: 'PricingPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PricingPages" */ '../views/pages/PricingPages.vue')
  },



  {
    path: '/productpages',
    name: 'ProductPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ProductPages" */ '../views/pages/ProductPages.vue')
  },


  {
    path: '/shoppingcardpages',
    name: 'ShoppingCardPages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShoppingCardPages" */ '../views/pages/ShoppingCardPages.vue')
  },


  {
    path: '/storefronts',
    name: 'Storefronts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "Storefronts" */ '../views/pages/Storefronts.vue')
  },



  {
    path: '/animations',
    name: 'Animations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "Animations" */ '../views/animations/Animations.vue')
  },





  {
    path: '/attrplugin',
    name: 'AttrPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "AttrPlugin" */ '../views/animations/AttrPlugin.vue')
  },

  {
    path: '/bezierplugin',
    name: 'BezierPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "BezierPlugin" */ '../views/animations/BezierPlugin.vue')
  },


  {
    path: '/cssplugin',
    name: 'CSSPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "CSSPlugin" */ '../views/animations/CSSPlugin.vue')
  },


  {
    path: '/cssruleplugin',
    name: 'CSSRulePlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "CSSRulePlugin" */ '../views/animations/CSSRulePlugin.vue')
  },


  {
    path: '/drawsvgplugin',
    name: 'DrawSVGPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "DrawSVGPlugin" */ '../views/animations/DrawSVGPlugin.vue')
  },



  {
    path: '/easelplugin',
    name: 'EaselPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "EaselPlugin" */ '../views/animations/EaselPlugin.vue')
  },

  {
    path: '/flipplugin',
    name: 'FlipPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "FlipPlugin" */ '../views/animations/FlipPlugin.vue')
  },

  {
    path: '/gsapexamples',
    name: 'GSAPExamples',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "GSAPExamples" */ '../views/animations/GSAPExamples.vue')
  },


  {
    path: '/gsapexample1',
    name: 'GSAPExampleNr1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "GSAPExampleNr1" */ '../components/animations/GSAPExamples/GSAPExampleNr1.vue')
  },


  {
    path: '/gsapexample3',
    name: 'GSAPExampleNr3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "GSAPExampleNr3" */ '../components/animations/GSAPExamples/GSAPExampleNr3.vue')
  },



  {
    path: '/inertiaplugin',
    name: 'InertiaPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "InertiaPlugin" */ '../views/animations/InertiaPlugin.vue')
  },

  {
    path: '/jquerygsapjs',
    name: 'jquery.gsap.js',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "jquery.gsap.js" */ '../views/animations/jquery.gsap.js.vue')
  },

  {
    path: '/modifiersplugin',
    name: 'ModifiersPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "ModifiersPlugin" */ '../views/animations/ModifiersPlugin.vue')
  },

  {
    path: '/morphsvgplugin',
    name: 'MorphSVGPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "MorphSVGPlugin" */ '../views/animations/MorphSVGPlugin.vue')
  },

  {
    path: '/motionpathplugin',
    name: 'MotionPathPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "MotionPathPlugin" */ '../views/animations/MotionPathPlugin.vue')
  },

  {
    path: '/physics2Dplugin',
    name: 'Physics2DPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "Physics2DPlugin" */ '../views/animations/Physics2DPlugin.vue')
  },

  {
    path: '/physicspropsplugin',
    name: 'PhysicsPropsPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "PhysicsPropsPlugin" */ '../views/animations/PhysicsPropsPlugin.vue')
  },

  {
    path: '/pixiplugin',
    name: 'PixiPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "PixiPlugin" */ '../views/animations/PixiPlugin.vue')
  },

  {
    path: '/scrambletextplugin',
    name: 'ScrambleTextPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "ScrambleTextPlugin" */ '../views/animations/ScrambleTextPlugin.vue')
  },

  {
    path: '/scrolltoplugin',
    name: 'ScrollToPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "ScrollToPlugin" */ '../views/animations/ScrollToPlugin.vue')
  },

  {
    path: '/scrolltriggerplugin',
    name: 'ScrollTriggerPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "ScrollTriggerPlugin" */ '../views/animations/ScrollTriggerPlugin.vue')
  },


  {
    path: '/scrolltriggernr1',
    name: 'ScrollTriggerNr1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "ScrollTriggerNr1" */ '../components/animations/ScrollTrigger/ScrollTriggerNr1.vue')
  },

  {
    path: '/scrolltriggernr2',
    name: 'ScrollTriggerNr2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "ScrollTriggerNr2" */ '../components/animations/ScrollTrigger/ScrollTriggerNr2.vue')
  },

/*
  {
    path: '/scrolltriggernr3',
    name: 'ScrollTriggerNr3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/!* webpackChunkName: "ScrollTriggerNr3" *!/ '../components/partials/PageTransitions/PageTransitionsNr4.vue')
  },

*/



  {
    path: '/textplugin',
    name: 'TextPlugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loadStorefrontsStorefrontsed when the route is visited.
    component: () => import(/* webpackChunkName: "TextPlugin" */ '../views/animations/TextPlugin.vue')
  },


  {
    path: '/buttonnr1',
    name: 'ButtonNr1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ButtonNr1" */ '../components/partials/Buttons/ButtonNr1.vue')
  },

  {
    path: '/hamburgernr1',
    name: 'HamburgerNr1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Hamburgers" */ '../components/partials/Hamburgers/HamburgerNr1.vue')
  },

]






const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router
