<template>

  <slot  name="partials" >
    <ul id="partials"
        v-if="partialsOn"
        class="opacity-0"
        :class="{ 'show' :  seen }"
       ref="partials"

    >
      <li

          tabindex="1"

          v-on:keyup.up="upKeyboard"
          v-on:keyup.down="downKeyboard"
          :id="item.id"

          v-for="(item, index) in partials" :key="item.id"

          :class='{"active-item": index === partialItem}'
      >

        <router-link

            @click="open"
            :to="item.link" class="hover:text-red-600"
        >{{item.name}}  {{item.available ? '✓' : ''}}</router-link>

      </li>
    </ul>
  </slot>

  <slot name="sections" >
    <ul id="sections"
        v-if="sectionsOn"
        class="opacity-0 "
        :class="{ 'show' :  seen }"
        ref="sections">
      <li
          v-on:keyup.up="upKeyboard"
          v-on:keyup.down="downKeyboard"
          v-for="(item, index) in sections" :key="item.name"
          :class='{activeItem: index === sectionItem}'>
        <router-link

            @click="open"
            :to="item.link"
            class="hover:text-red-600">{{item.name}}  {{item.available ? '✓' : ''}}</router-link>
      </li>
    </ul>
  </slot>


  <slot name="pages">
    <ul id="pages"
        v-if="pagesOn"
        class="opacity-0 "
        :class="{ 'show' :  seen }"
    >
      <li

          v-on:keyup.up="upKeyboard"
          v-on:keyup.down="downKeyboard"
          v-for="(item, index) in pages" :key="item.name"
          :class='{activeItem: index === pageItem}'
      >

        <router-link
            @click="open"
            :to="item.link" class="hover:text-red-600">{{item.name}}  {{item.available ? '✓' : ''}}</router-link>

      </li>
    </ul>
  </slot>



  <slot name="animations" >
    <ul id="animations"
        v-if="animationsOn"
        class="opacity-0"
        :class="{ 'show' :  seen }"
    >
      <li

          v-on:keyup.up="upKeyboard"
          v-on:keyup.down="downKeyboard"
          v-for="(item,index) in animations" :key="item.name"
          :class='{activeItem: index === animationItem}'
      >

        <router-link

            @click="open"
            :to="item.link" class="hover:text-red-600">{{item.name}}  {{item.available ? '✓' : ''}}</router-link>

      </li>


    </ul>



  </slot>


</template>

<script>



export default {
  name: "SidebarList",

  props: ['seen','partialsOn','pagesOn','sectionsOn', 'animationsOn','activeOpen','activeClose','open'],



  data () {

    return {


      activeItem : 'active-item',

      partials: [
        {name: 'Accordions', link: '/accordions' , id: '1', available : false },
        {name: 'Alert Banners', link: '/alertbanners' , id: '2',  available : false },
        {name: 'Badges', link: '/badges', id: '3',  available : false },
        {name: 'Borders', link: '/borders', id: '4',  available : false },
        {name: 'Breadcrumbs', link: '/breadcrumbs', id: '5',  available : false },
        {name: 'Buttons', link: '/buttons', id: '6',  available : true },
        {name: 'Calendars', link: '/calendars', id: '7',  available : false },
        {name: 'Cards', link: '/cards',id: '8',  available : true },
        {name: 'CheckboxesToggles', link: '/checkboxestoggles', id: '9',  available : false },
        {name: 'Dropdowns', link: '/dropdowns', id: '10' , available : true },
        {name: 'Flyout Menus', link: '/flyoutmenus', id: '11',  available : false },
        {name: 'HamburgerMenus', link: '/hamburgermenus',id: '12',  available : true },
        {name: 'Hamburgers', link: '/hamburgers', id: '13',  available : true },
        {name: 'Icons', link: '/icons', id: '14',  available : false },
        {name: 'Input Fields', link: '/inputfields', id: '15',  available : true },
        {name: 'Loading Spinners', link: '/loadingspinners', id: '16',  available : true },
        {name: 'Menus', link: '/menus', id: '17',  available : true },
        {name: 'Modals', link: '/modals', id: '18',  available : false },
        {name: 'Navigations', link: '/navigations', id: '19',  available : false },
        {name: 'Notifications', link: '/notifications', id: '20',  available : false },
        {name: 'Page Transitions', link: '/pagetransitions',id: '21',   available : true },
        {name: 'Paginations', link: '/paginations', id: '22',  available : true },
        {name: 'Select Menus', link: '/select', id: '23',  available : false },
        {name: 'Sidebars', link: '/sidebars', id: '24',  available : false },
        {name: 'Slide Overs', link: '/slideovers', id: '25',  available : false },
        {name: 'Steps', link: '/steps', id: '26',  available : false },
        {name: 'Tabs', link: '/tabs', id: '27',  available : true },
        {name: 'Timelines', link: '/Timelines', id: '28',  available : true },
        {name: 'Tooltips', link: '/tooltips', id: '29',  available : false },

      ],

      sections: [
        {name: '404 Pages', link: '/404pages' , available : false },
        {name: 'Authentications',link:'/authentications' , available : false },
        {name: 'Blogs',link:'/blogs' , available : false },
        {name: 'Contacts',link:'/contacts' , available : false },
        {name: 'Contents',link:'/contents' , available : false },
        {name: 'CTA',link:'/cta' , available : false },
        {name: 'FAQ',link:'/faq' , available : false },
        {name: 'Features',link:'/features' , available : false },
        {name: 'Footers',link:'/footers' , available : true },
        {name: 'Header',link:'/headers' , available : false },
        {name: 'Heros',link:'/Heros' , available : false },
        {name: 'Lists',link:'/lists' , available : false },
        {name: 'Newsletters',link:'/newsletters' , available : false },
        {name: 'OnBoardings',link:'/onboarding' , available : false },
        {name: 'Pricing',link:'/pricing' , available : false },
        {name: 'Stats',link:'/stats' , available : false },
        {name: 'Sliders',link:'/sliders' , available : true },
        {name: 'Teams',link:'/team' , available : false },
        {name: 'Testimonials',link:'/testimonials' , available : false },


      ],

      pages: [
        {name: 'About Pages',link:'/aboutpages' , available : false },
        {name: 'Animated Pages',link:'/animatedpages' , available : false },
        {name: 'Category Pages',link:'/categorypages' , available : false },
        {name: 'Checkout Pages',link:'/checkoutpages' , available : false },
        {name: 'Landing Pages',link:'/landingpages' , available : true },
        {name: 'Order Detail Pages',link:'/orderdetailpages' , available : false },
        {name: 'Order History Pages',link:'/orderhistorypages' , available : false },
        {name: 'Pricing Pages',link:'/pricingpages' , available : false },
        {name: 'Product Pages',link:'/productpages' , available : false },
        {name: 'Shopping Card Pages',link:'/shoppingcardpages' , available : false },
        {name: 'Storefronts',link:'/storefronts' , available : false },


      ],


      animations: [
        {name: 'Animations',link:'/animations' , available : false },
        {name: 'AttrPlugin',link:'/attrplugin' , available : true },
        {name: 'BezierPlugin',link:'/bezierplugin' , available : false },
        {name: 'CSSPlugin',link:'/cssplugin' , available : false },
        {name: 'CSSRulePlugin',link:'/cssruleplugin' , available : false },
        {name: 'DrawSVGPlugin',link:'/drawsvgplugin' , available : true },
        {name: 'EaselPlugin',link:'/easelplugin' , available : true },
        {name: 'FlipPlugin',link:'/flipplugin' , available : false },
        {name: 'GSAPExamples',link:'/gsapexamples' , available : true },
        {name: 'InertiaPlugin',link:'/inertiaplugin' , available : false },
        {name: 'jquery.gsap.js',link:'/jquerygsapjs' , available : false },
        {name: 'ModifiersPlugin',link:'/modifiersplugin' , available : false },
        {name: 'MorphSVGPlugin',link:'/morphsvgplugin' , available : false },
        {name: 'MotionPathPlugin',link:'/motionpathplugin' , available : false },
        {name: 'Physics2DPlugin',link:'/physics2Dplugin' , available : false },
        {name: 'PhysicsPropsPlugin',link:'/physicspropsplugin' , available : false },
        {name: 'PixiPlugin',link:'/pixiplugin' , available : false },
        {name: 'ScrambleTextPlugin',link:'/scrambletextplugin' , available : false },
        {name: 'ScrollToPlugin',link:'/scrolltoplugin' , available : false },
        {name: 'ScrollTriggerPlugin',link:'/scrolltriggerplugin' , available : true },
        {name: 'TextPlugin',link:'/textplugin' , available : true },


      ],


      partialItem : 0,
      sectionItem: 0,
      pageItem: 0,
      animationItem: 0,
    }

  },

  methods: {

    upKeyboard(){

      if (this.partialItem > 0 || this.sectionItem > 0 || this.pageItem > 0 || this.animationItem > 0) {
        this.partialItem--
        this.sectionItem--
        this.pageItem--
        this.animationItem--
      }



    },

    downKeyboard(){

  if (this.partialItem < this.partials.length - 1 ) {
    this.partialItem++

  }
      if (this.$refs.sections === true) {
        if(this.sectionItem < this.sections.length - 1 ) {this.sectionItem++ }
      }

      if(this.sectionItem < this.sections.length - 1 ) { this.sectionItem++}
      if(this.pageItem < this.pages.length - 1 ) {  this.pageItem++}
      if(this.animationItem < this.animations.length - 1) {  this.animationItem++}
    },

  },

}
</script>

<style scoped>


.show {
  opacity: 1;
}

</style>
