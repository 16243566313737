<template >


<Logo/>
</template>

<script>

import Logo from "@/components/animations/Logo";


export default {
  name: "Home",



  components: {

Logo

  },



  mounted() {


  }

}
</script>

<style scoped>

</style>
