<template>
  <section class="w-full overflow-hidden text-center" >

    <router-view/>

   <dashboard

   />
  </section>
</template>

<script>




import Dashboard from "./components/Dashboard";
export default {
  name: 'App',
  components: {
      Dashboard

  }
}
</script>

<style lang="scss">

body {

  background-color: #51b5b5;

}

#App {

  overflow: hidden;

}
</style>
