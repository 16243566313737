<template>

  <h1 class="text-center text-3xl mb-10">
    c1chy COMPONENTS
  </h1>

</template>

<script>

import {gsap} from "gsap";
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'

gsap.registerPlugin(DrawSVGPlugin, MotionPathPlugin);

export default {
  name: "Logo",

  methods: {


    animation() {

      let tl = gsap.timeline();


      tl
          .fromTo('.big', {
            opacity: 0,
          }, {

            opacity: 1,
            duration: 3,
            transformOrigin: "50% 50%",
            rotate: "360_cw",
            force3D: true,
          }, 'in')


    }


  },





  mounted() {

    let tl = gsap.timeline();

    tl
        .fromTo('.a', {
          yPercent: -100,
          opacity: 0
        }, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          opacity: 1,
          stagger: 1,
          ease: "power3.in",
          force3D: true,
        }, 'in')


    tl
        .fromTo('.c', {
          yPercent: -100,
          opacity: 0,
          delay: 0.5,
        }, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          delay: 0.5,
          opacity: 1,
          stagger: 1,
          ease: "power3.in",
          force3D: true,
        }, 'in')


    tl
        .fromTo('.o', {
          yPercent: -100,
          opacity: 0,
          delay: 1,
        }, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          delay: 1,
          opacity: 1,
          stagger: 1,
          ease: "power3.in",
          force3D: true,
        }, 'in')


    tl
        .fromTo('.u', {
          yPercent: -100,
          opacity: 0,
          delay: 1.5,
        }, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          delay: 1.5,
          opacity: 1,
          stagger: 1,
          ease: "power3.in",
          force3D: true,
        }, 'in')


    tl
        .fromTo('.r', {
          yPercent: -100,
          opacity: 0,
          delay: 2,
        }, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          delay: 2,
          opacity: 1,
          stagger: 1,
          ease: "power3.in",
          force3D: true,
        }, 'in')


    tl
        .fromTo('.s', {
          yPercent: -100,
          opacity: 0,
          delay: 2.5,
        }, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          delay: 2.5,
          opacity: 1,
          stagger: 1,
          ease: "power3.in",
          force3D: true,
        }, 'in')


    tl
        .fromTo('.e', {
          yPercent: -100,
          opacity: 0,
          delay: 3,
        }, {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          delay: 3,
          opacity: 1,
          stagger: 1,
          ease: "power3.in",
          force3D: true,
        }, 'in')



    tl
        .fromTo('.big', {
          opacity: 0,
        }, {

          delay:4,
          opacity: 1,
          duration: 3,
          transformOrigin: "50% 50%",
          rotate: "720_cw",
          force3D: true,
          ease:"bounce.in"
        }, 'in')


  }


}
</script>

<style scoped lang="scss">


</style>
