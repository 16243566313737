<template>

  <nav class="fixed flex top-0 ">



  <div id="sidebar"


       v-on:keyup.right="openKeyboard"
       v-on:keyup.left="closeKeyboard"

       :class="seen ? activeOpen : ''"
       class="h-screen flex-col w-2 menu bg-white text-white flex flex-col items-center content-evenly nunito static shadow">


<a href="/">
    <img :src="image"/></a>

    <ul
        role="menu"
        id="sidebarList"
        class="list-reset opacity-0 my-auto text-center"
        :class="seen ? activeOpen : ''"
    >

      <li class="my-2 md:my-0"
          v-on:keyup.up="upKeyboard"
          v-on:keyup.down="downKeyboard"

          v-for="(button, index ) in buttons" :key="button.id"

          :class='{"active-item": index === currentItem}'




      >
        <button
            role="menuitem"
            aria-haspopup="true"
            ref="button"
            v-on:keyup.enter="enter"
            class="inline-block py-1 md:py-3  align-middle text-white no-underline"
            @click="open"
            :id="button.id"
        >
          <span class="w-full inline-block pb-1 md:pb-0 text-md hover:text-blue-600">
          {{button.name}}  </span>
        </button>
      </li>


    </ul>
  </div>


  <Sidebar
      :open="open"
      :seen="seen"
      :activeOpen="activeOpen"
      :activeClose="activeClose"
      :partialsOn="partialsOn"
      :sectionsOn="sectionsOn"
      :pagesOn="pagesOn"
      :animationsOn="animationsOn"
      :partialItem="partialItem"
  >

  </Sidebar>
  </nav>

</template>

<script>
import image from "/src/assets/logo.png"
import Sidebar from "@/components/Sidebar";

export default {
  name: "Dashboard",

  components: {Sidebar},


  props:['partialItem','activeItem','id'],


  data() {

    return {

      image: image,
      activeClose:'active-close',
      activeOpen:'active-open',
      seen: false,
      partialsOn:false,
      pagesOn:false,
      sectionsOn:false,
      animationsOn:false,

      buttons: [

        {name:'Partials',id:'partials',buttonLi:'1'},
        {name:'Page sections',id:'sections',buttonLi:'2'},
        {name:'Pages',id:'pages',buttonLi:'3'},
        {name:'Animations',id:'animations',buttonLi:'4'},

      ],




      currentItem: 0,



    }
  },




  methods: {


    open(key) {


      this.seen = !this.seen


      if (key.currentTarget.id === "partials" ) {

        this.partialsOn = true
        this.sectionsOn = false
        this.pagesOn = false
        this.animationsOn = false

      }

      if (key.currentTarget.id === "sections" ) {

        this.partialsOn = false
        this.sectionsOn = true
        this.pagesOn = false
        this.animationsOn = false
      }

      if (key.currentTarget.id === "pages" ) {


        this.partialsOn = false
        this.sectionsOn =false
        this.pagesOn =  true
        this.animationsOn = false
      }

      if (key.currentTarget.id === "animations" ) {


        this.partialsOn = false
        this.sectionsOn = false
        this.pagesOn = false
        this.animationsOn = true
      }



    },


    openKeyboard(){

    },



    closeKeyboard(){

    },


    enter(){



      this.seen = !this.seen

      if (this.currentItem === 0) {

        console.log("partials")
        document.querySelector("#partials").click()
        document.querySelector(".active-item").focus()
        console.log(document.querySelector(".active-item"))
        console.log(this.id)

        if (this.partialsOn === true) {

          console.log("partials ON")
        }


      }



      if (this.currentItem === 1) {



        document.querySelector("#sections").click()
      }

      if (this.currentItem === 2) {



        document.querySelector("#pages").click()
      }


      if (this.currentItem === 3) {



        document.querySelector("#animations").click()
      }



    },

    upKeyboard(){

      if (this.currentItem > 0) {
        this.currentItem--
      }

    },
    downKeyboard(

    ){
      if (this.currentItem < 3) {
        this.currentItem++
      }
    },



},


}
</script>
<style scoped>


button:focus { outline: none; }

.active-close {
  /*  background-color : green;*/
  width: 0;
  transition: ease-in-out all 1s;
}
.active-open {
  transition: ease-in-out all 1s;
  opacity: 1;
  box-shadow: none;
  width: 175px;
}

.nunito {
  font-family: 'nunito', font-sans;
}
.border-b-1 {
  border-bottom-width: 1px;
}
.border-l-1 {
  border-left-width: 1px;
}
hover\:border-none:hover {
  border-style: none;
}
#sidebar {
  transition: ease-in-out all .3s;
  z-index: 9999;
  justify-content: center;
  box-sizing: border-box; /* <------- required */
  float: left; /* <------------------ required */
  background-color: saddlebrown;
  border-right: 1px solid white;
}
.list-reset li a {
  color: white;
}
#sidebar2 {
  transition: ease-in-out all .3s;
  z-index: 1000;
  overflow: hidden;
  background-color: saddlebrown;
}
#sidebar2:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  /*shadow-2xl*/
}
#sidebar span {
  position: relative;
  transition: ease-in-out all .1s;
}
#sidebar:hover {
  width: 175px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  /*shadow-2xl*/
}
#sidebar:hover span {
  opacity: 1;
}
.list-reset:hover {
  opacity: 1;
}
#sidebar:hover ul {
  opacity: 1;
  transition: ease-in-out all 1s;
}


.myCustomHover:hover {
  width: 175px;
}
.myCustomActive:active {
  width: 0;
}
nav {

  z-index: 10000;
}


</style>
